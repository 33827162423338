const keySlugMapping: Record<string, string> = {
  "match-1": "Maple_Leaf_Rag_Scott_Joplin",
  "match-2": "s.-xarhakos---stoy-thoma---greek-music-055",
  "match-3": "may-be---yiruma",
  "match-4": "Sweden_Minecraft",
  "match-5": "arabesque-l.-66-no.-1-in-e-major",
  "match-6": "gol-e-sang---anoushiravan-rohani",
  "match-7": "philip-glass---facades",
  "match-8": "fear-not-this-night-guild-wars-2-piano-version",
  "match-9": "dragonborn---skyrim-theme-song-piano-solo",
  "match-10": "s.-xarhakos---mpoyrnovalia---greek-music-079",
  "match-11": "claude-debussy---la-fille-aux-cheveux-de-lin",
  "match-12": "il-coure-mio---anoushiravan-rohani",
  "match-13": "philip-glass---pruit-igoe",
  "match-14": "gladiolus-rag---scott-joplin---1907",
  "match-15": "it-s-your-day---yiruma",
  "match-16": "dry-hands-minecraft",
};

export default keySlugMapping;
