import * as React from "react";

const Index = () => {
  return (
    <div
      style={{
        marginTop: 100,
        marginBottom: 100,
        textAlign: "left",
        width: "600px",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <h1>Texts</h1>
    </div>
  );
};

export default Index;
